import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import ResourceDetailLayout from 'components/ResourceDetailLayout';

import type { ContentfulComponentHeading, ContentfulTemplateVideos } from 'graphqlTypes';
import type { FC } from 'react';

interface VideoDataType {
  videoData: ContentfulTemplateVideos;
  cardDeckVideoHeading: ContentfulComponentHeading;
  cardDeckWebinarHeading: ContentfulComponentHeading;
}

const Page: FC<PageProps<VideoDataType>> = ({
  data: { cardDeckVideoHeading, cardDeckWebinarHeading, videoData },
  location,
}) => (
  <Layout slug={location.pathname} seo={videoData.seo}>
    <ResourceDetailLayout
      {...videoData}
      cardDeckVideoHeading={cardDeckVideoHeading}
      cardDeckWebinarHeading={cardDeckWebinarHeading}
      displayBreadcrumbs={videoData.seo?.displayBreadcrumbs}
      cardsHeading={videoData.resourceType === 'Video' ? cardDeckVideoHeading : cardDeckWebinarHeading}
    />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templateVideoQuery($slug: String!) {
    videoData: contentfulTemplateVideos(slug: { eq: $slug }) {
      ...contentfulTemplateVideos
    }
    cardDeckVideoHeading: contentfulComponentHeading(contentful_id: { eq: "5YaiHe5KDD0mXK4QW31IY1" }) {
      ...contentfulComponentHeading
    }
    cardDeckWebinarHeading: contentfulComponentHeading(contentful_id: { eq: "4MyCic4rm07tvUKC6niwp2" }) {
      ...contentfulComponentHeading
    }
  }
`;
